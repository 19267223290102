import * as firebase from 'firebase/app'
import { functions } from '../firebase'

const PERMISSION_GRANTED = 'granted'
const vapidKey = 'BCDD-yDrb1pyCuQZOWZwvOQVO9zCjtOeYMHJDFsU1JM73w1X9XGf4n4iVPAj_LNlVAdpq_wWzw51DepU1D_uk88'

// Disable notification in android web view
let supported
let messaging
if ('ANDROID_WEB_VIEW' in window) {
  supported = false
} else if(firebase.messaging.isSupported()) {
  try {
    messaging = firebase.messaging()
    messaging.onMessage(receiveMessage)
    supported = true
  } catch (e) {
    supported = false
  }
} else {
  supported = false
}

// Enabled state of notification API
let enabled

// FCM token for this device
let token

export function isNotificationSupported() : boolean {
  return supported
}

async function enable () {
  if (enabled) {
    return true
  }

  // No notifications if API is not supported
  if (!('Notification' in window)) {
    return false
  }

  if (Notification.permission !== PERMISSION_GRANTED) {
    const permission = await requestPermission()
    if (permission !== PERMISSION_GRANTED) {
      return false
    }
  }

  try {
    token = await messaging.getToken({ vapidKey })
  } catch (e) {
    return false
  }

  return true
}

export async function subscribeNotification (orgId: string): Promise<boolean> {
  if (enabled == null) {
    enabled = await enable()
    if (!enabled) {
      return false
    }
  }

  const result = await functions.subscribeWebNotification({
    //orgId: 'wyq9mcB52FT4aMTNCSbI',
    orgId,
    token
  })

  return result.data
}

export async function unsubscribeNotification (orgId: string): Promise<boolean> {
  if (enabled == null) {
    enabled = await enable()
    if (!enabled) {
      return false
    }
  }

  const result = await functions.unsubscribeWebNotification({
    // orgId: 'wyq9mcB52FT4aMTNCSbI',
    orgId,
    token
  })

  return result.data
}


function receiveMessage (payload) {
  const opts = { ...payload.notification }
  if (payload.fcm_options?.image != null) {
    opts.image = payload.fcm_options.image
  }

  const notification = new Notification(payload.notification.title, opts)

  if (payload.data.eventId != null) {
    notification.onclick = function(e) {
      e.preventDefault()
      window.open(`https://beerscovery.com/event/${payload.data.eventId}`)
    }
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API/Using_the_Notifications_API
function checkNotificationPromise (): boolean {
  try {
    Notification.requestPermission().then()
  } catch (e) {
    return false
  }
  return true
}

function webkit_requestPermission () {
  return new Promise((resolve) => {
    Notification.requestPermission(resolve)
  })
}

async function requestPermission () {
  if(checkNotificationPromise()) {
    return Notification.requestPermission()
  } else {
    return webkit_requestPermission()
  }
}
