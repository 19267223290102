import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import './directives/DateDisplay'
import './directives/LinkDisplay'

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created () {
    store.dispatch('loadEvents')
    store.dispatch('loadPromotions')
    store.dispatch('loadFollowedOrgs')
    store.dispatch('loadEventReactions')
    store.dispatch('loadEnteredGiveaways')
  }
}).$mount("#app")
