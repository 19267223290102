/* eslint-disable  @typescript-eslint/no-explicit-any */
// Above rule disabled due to 'unknown' type of Vuex store state

import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { eventIndex, orgIndex } from '../util/Algolia'
import { getCurrentPosition } from '../util/Geolocation'
import { collections, analytics } from '../firebase'
import { subscribeNotification, unsubscribeNotification } from '../util/Notification'
import distance from '@turf/distance'
import * as AppEvents from '../model/AppEvents'
import { Menu } from '../model/Menu'
import { FollowedOrg } from '../model/FollowedOrg'

import * as firebase from 'firebase/app'

// Install Vuex
Vue.use(Vuex);

const EVENTS_PAGE_SIZE = 30
const BROWSE_ORGS_PAGE_SIZE = 30
const FOLLOWED_ORGS_STORAGE_KEY = 'followedOrgs'
const EVENT_REACTIONS_STORAGE_KEY = 'event_reactions'
const ENTERED_GIVEAWAYS_KEY = 'entered_giveaways'

const followedOrgs: Array<FollowedOrg> = []
const eventReactions = new Map<string, Array<string>>()
const enteredGiveaways = new Array<string>()
const menus = new Array<Menu>()

function persistFollowedOrgs () {
  localStorage.setItem(FOLLOWED_ORGS_STORAGE_KEY, JSON.stringify(state.followedOrgs))
}

function persistEventReactions () {
  localStorage.setItem(EVENT_REACTIONS_STORAGE_KEY, JSON.stringify(state.eventReactions))
}

function persistEnteredGiveaways () {
  localStorage.setItem(ENTERED_GIVEAWAYS_KEY, JSON.stringify(state.enteredGiveaways))
}

// State
const state = {
  activeTab: 0,
  activePromotion: null,
  activeEvent: null,
  activeMenu: null,
  discoverEvents: [],
  promotions: [],
  upcomingFestEvents: [],
  mapEvents: [],
  mapOrgs: [],
  discoverSort: 'lastUpdated',
  discoverFeedTab: 0, 
  myFeedEvents: [],
  myFeedSort: 'lastUpdated',
  browseOrgsSort: 'numEvents',
  browseOrgs: [],
  orgEvents: [],
  orgLinks: [],
  orgMenus: [],
  orgEventsSort: 'lastUpdated',
  searchResultsOrgs: null,
  searchResultsEvents: null,
  activeOrg: {
    id: ''
  },
  algoliaEvents: [],
  followedOrgs,
  eventReactions,
  enteredGiveaways,
  menus
}

// Store
export default new Vuex.Store({
  state,

  actions: {
    loadEvents: firestoreAction(async ({ bindFirestoreRef }) => {
      // TODO: Temporary fix to infinite scrolling bug
      if (state.discoverEvents.length > 0 && state.discoverEvents.length % EVENTS_PAGE_SIZE !== 0) {
        return
      }

      // Update the query limit length
      const limit = state.discoverEvents.length + EVENTS_PAGE_SIZE

      // Ref query
      const discoverEventsRef = collections.events
        .where('parentEventId', '==', null)
        .where('isPublished', '==', true)
        .where('isActive', '==', true)
        .where('organization.isActive', '==', true)
        .where('isPrimaryEvent', '==', true)
        .orderBy(state.discoverSort, 'desc')
        .limit(limit)


      return bindFirestoreRef('discoverEvents', discoverEventsRef, { wait: true })
    }),
    
    loadUpcomingFests: firestoreAction(async ({ bindFirestoreRef }) => {
      const today = new Date()
      today.setHours(0)
      today.setMinutes(0)
      today.setSeconds(0)

     // Ref query
     const upcomingFestEventsRef = collections.events
      .where('isPublished', '==', true)
      .where('isActive', '==', true)
      .where('organization.isActive', '==', true)
      .where('type', '==', "FESTIVAL")
      .where('date', ">=", today)
      .limit(1000)

      return bindFirestoreRef('upcomingFestEvents', upcomingFestEventsRef, { wait: true })
    }),

    loadUpcomingToday: firestoreAction(async ({ bindFirestoreRef }) => {
      // Update the query limit length
      const limit = 100
      
      // Ref query
      const discoverEventsRef = collections.events
        .where('isPublished', '==', true)
        .where('isActive', '==', true)
        .where('organization.isActive', '==', true)
        .orderBy('date')
        .limit(limit)


      return bindFirestoreRef('discoverEvents', discoverEventsRef, { wait: true })
    }),

    loadMyFeedEvents: firestoreAction(async ({ bindFirestoreRef }) => {
      // Nothing to load if no followed orgs, just reset
      if (state.followedOrgs.length === 0) {
        // TODO reset
        return
      }

      // TODO: Temporary fix to infinite scrolling bug
      if (state.myFeedEvents.length > 0 && state.myFeedEvents.length % EVENTS_PAGE_SIZE !== 0) {
        return
      }

      // Update the query limit length
      const limit = state.myFeedEvents.length + EVENTS_PAGE_SIZE

      const followedOrgIds = state.followedOrgs.map(org => org.id)

      // Ref query
      const myFeedEventsRef = collections.events
        .where('parentEventId', '==', null)
        .where('isPublished', '==', true)
        .where('isActive', '==', true)
        .where('organization.isActive', '==', true)
        .where('organization.id', 'in', followedOrgIds)
        .orderBy(state.myFeedSort, 'desc')
        .limit(limit)

      // Wait for the ref to update, then update the actual state used for
      // rendering only if new records were loaded
      // (this prevents an empty "flash" render)
      return bindFirestoreRef('myFeedEvents', myFeedEventsRef, { wait: true })
    }),

    async geosearch ({ commit }, geoloc: GeolocationPosition) {
      // determine distance from key
      const key = state.discoverSort
      let miles = 50
      if (key === 'geo:20') {
        miles = 20
      } else if (key === 'geo:35') {
        miles = 35
      }
      const meters = Math.round(miles * 1609.34)

      const lat = geoloc.coords.latitude
      const lng = geoloc.coords.longitude

      // search
      const result = await eventIndex.search('', {
        aroundLatLng: `${lat}, ${lng}`,
        aroundRadius: meters,
        filters: 'isPublished:true AND isActive:true AND organization.isActive:true AND isPrimaryEvent:true'
      })

      const hits: Array<Record<string, any>> = result.hits

      // add distance to results
      const userCoord = [lat, lng]
      hits.forEach(hit => {
        const eventCoord = [
          hit._geoloc.lat,
          hit._geoloc.lng
        ]
        const dist = distance(userCoord, eventCoord, {
          units: 'miles'
        })
        hit.distance = Math.round(dist)
      })

      // load results
      commit('updateDiscoverEvents', hits)
      return hits
    },

    async geosearchMapEvents ({ commit }, bbox) {
      const timestamp = Date.now() / 1000

      // search
      const result = await eventIndex.search('', {
        insideBoundingBox: [bbox],
        filters: `isPublished:true AND isActive:true AND organization.isActive:true AND (date._seconds >= ${timestamp} OR endDate._seconds >= ${timestamp})`
      })

      const hits: Array<Record<string, any>> = result.hits

      // load results
      commit('updateMapEvents', hits)
      return hits
    },

    async geosearchMapOrgs ({ commit }, bbox) {
      // search
      const result = await orgIndex.search('', {
        insideBoundingBox: [bbox],
        filters: 'isActive:true'
      })

      const hits: Array<Record<string, any>> = result.hits

      // load results
      commit('updateMapOrgs', hits)
      return hits
    },

    loadOrgEvents: firestoreAction(async ({ bindFirestoreRef }) => {
      // TODO: Temporary fix to infinite scrolling bug
      if (state.orgEvents.length > 0 && state.orgEvents.length % EVENTS_PAGE_SIZE !== 0) {
        return
      }

      // Update the query limit length
      const limit = state.orgEvents.length + EVENTS_PAGE_SIZE

      // Ref query
      const orgEventsRef = collections.events
        .where('parentEventId', '==', null)
        .where('isPublished', '==', true)
        .where('isActive', '==', true)
        .where('organization.id', '==', state.activeOrg.id)
        .where('isPrimaryEvent', '==', true)
        .orderBy(state.orgEventsSort, 'desc')
        .limit(limit)

      return bindFirestoreRef('orgEvents', orgEventsRef, { wait: true })
    }),

    loadOrgLinks: firestoreAction(async ({ bindFirestoreRef }) => {
      // Ref query
      const orgLinksRef = collections.links
        .where('isActive', '==', true)
        .where('orgId', '==', state.activeOrg.id)
        .orderBy('order')
        .limit(100)

      return bindFirestoreRef('orgLinks', orgLinksRef, { wait: true })
    }),

    loadOrgMenus: firestoreAction(async ({ bindFirestoreRef }) => {
      // Ref query
      const orgMenusRef = collections.menus
        .where('isActive', '==', true)
        .where('organization.id', '==', state.activeOrg.id)
        .orderBy('order')
        .limit(10)

      return bindFirestoreRef('orgMenus', orgMenusRef, { wait: true })
    }),

    loadPromotions: firestoreAction(async ({ bindFirestoreRef }) => {
      const today = new Date()
      today.setHours(0)
      today.setMinutes(0)
      today.setSeconds(0)

      // Ref query
      const promoRef = collections.promotions
        .where('isActive', '==', true)
        .where('isPublic', '==', true)
        .where('organization.isActive', '==', true)
        .limit(1000)

      return bindFirestoreRef('promotions', promoRef, { wait: true })
    }),

    loadOrgs: firestoreAction(async ({ bindFirestoreRef, unbindFirestoreRef }) => {
      // BUG: For some reason, having an activeOrg ref causes this query to
      // return that as a result immediately, but does not wait for the rest
      // of the results to be applied before returning
      unbindFirestoreRef('activeOrg')

      // Update the query limit length
      const limit = state.browseOrgs.length + BROWSE_ORGS_PAGE_SIZE

      // Ref query
      const browseOrgsRef = collections.organizations
        .where('isActive', '==', true)
        .orderBy(state.browseOrgsSort, state.browseOrgsSort === 'numEvents' ? 'desc' : undefined)
        .limit(limit)

      return bindFirestoreRef('browseOrgs', browseOrgsRef, { wait: true })
    }),

    async sortBrowseOrgs ({ commit, dispatch }, key) {
      // No-op if sort didn't actually chanrge
      if (state.browseOrgsSort === key) {
        return
      }

      // Update key
      commit('setBrowseOrgsSort', key)
      // Reload orgs from firestore
      dispatch('loadOrgs')
    },

    async sortDiscover ({ commit, dispatch }, key) {
      // No-op if sort didn't actually chanrge
      if (state.discoverSort === key) {
        return
      }

      // If this is a geo query, load from Algolia
      if (key.startsWith('geo:')) { 
        try {
          // This will invoke a permissions prompt, and throw
          // if it fails
          const geoloc = await getCurrentPosition()

          // Update key
          commit('setDiscoverSort', key)

          return dispatch('geosearch', geoloc)

        } catch (e) {
          // Either we couldn't get current position or the
          // user denied the permission. Don't change the sort key then
          commit('setDiscoverSort', state.discoverSort)
        }

      } else {
        // Update key
        commit('setDiscoverSort', key)
        // Reload events from firestore
        return dispatch('loadEvents')
      }
    },

    async setDiscoverFeedTab ({ commit, dispatch }, key) {
      state.discoverFeedTab = key
      // commit('setDiscoverFeedTab', key)
    },
    
    async sortMyFeed ({ commit, dispatch }, key) {
      // No-op if sort didn't actually chanrge
      if (state.myFeedSort === key) {
        return
      }

      // Update key
      commit('setMyFeedSort', key)

      // Reload events
      return dispatch('loadMyFeedEvents')
    },

    async sortOrgEvents ({ commit, dispatch }, key) {
      // No-op if sort didn't actually chanrge
      if (state.orgEventsSort === key) {
        return
      }

      // Update key
      commit('setOrgEventsSort', key)

      // Reload events
      return dispatch('loadOrgEvents')
    },


    loadEventDetails: firestoreAction(async (context, id) => {
      const eventRef = collections.events.doc(id)
      return context.bindFirestoreRef('activeEvent', eventRef)
    }),

    loadPromotionDetails: firestoreAction(async (context, id) => {
      const eventRef = collections.promotions.doc(id)
      return context.bindFirestoreRef('activePromotion', eventRef)
    }),

    loadOrg: firestoreAction(async (context, id) => {
      context.commit('resetOrgEvents')
      const ref = collections.organizations.doc(id)
      return context.bindFirestoreRef('activeOrg', ref)
    }),


    loadMenu: firestoreAction(async (context, id) => {
      const eventRef = collections.menus.doc(id)
      return context.bindFirestoreRef('activeMenu', eventRef)
    }),

  
    loadEnteredGiveaways ({ commit }) {
      try {
        const json = localStorage.getItem(ENTERED_GIVEAWAYS_KEY)
        if (json != null) {
          const giveaways = JSON.parse(json)
          if (giveaways != null) {
            commit('setEnteredGiveaways', giveaways)
          }
        }
      } catch (e) {
        // The JSON in local storage was corrupted. Reset it.
        try {
          localStorage.setItem(ENTERED_GIVEAWAYS_KEY, '[]')
        } catch (e) {
          // ignore
        }
      }
    },

    loadEventReactions ({ commit }) {
      try {
        const json = localStorage.getItem(EVENT_REACTIONS_STORAGE_KEY)
        if (json != null) {
          const reactions = JSON.parse(json)
          if (reactions != null) {
            commit('setEventReactions', reactions)
          }
        }
      } catch (e) {
        // The JSON in local storage was corrupted. Reset it.
        try {
          localStorage.setItem(EVENT_REACTIONS_STORAGE_KEY, '{}')
        } catch (e) {
          // ignore
        }
      }
    },

    load ({ commit }) {
      try {
        const json = localStorage.getItem(FOLLOWED_ORGS_STORAGE_KEY)
        if (json != null) {
          const orgs = JSON.parse(json)
          if (orgs != null) {
            commit('setFollowedOrgs', orgs)
          }
        }
      } catch (e) {
        // The JSON in local storage was corrupted. Reset it.
        try {
          localStorage.setItem(FOLLOWED_ORGS_STORAGE_KEY, '[]')
        } catch (e) {
          // ignore
        }
      }
    },

    async subscribeOrg (_, orgId) {
      const result = await subscribeNotification(orgId)
      if (!result) {
        return
      }

      const index = state.followedOrgs.findIndex(org => org.id === orgId)
      if (index >= 0) {
        Vue.set(state.followedOrgs[index], 'subscribed', true)

        analytics.logEvent(AppEvents.SUBSCRIBE_ORG, {
          org_id: orgId
        })

        persistFollowedOrgs()
      }
    },

    async unsubscribeOrg (_, orgId) {
      const result = await unsubscribeNotification(orgId)
      if (!result) {
        return
      }

      const index = state.followedOrgs.findIndex(org => org.id === orgId)
      if (index >= 0) {
        Vue.set(state.followedOrgs[index], 'subscribed', false)

        analytics.logEvent(AppEvents.UNSUBSCRIBE_ORG, {
          org_id: orgId
        })

        persistFollowedOrgs()
      }
    },

    textSearch ({ commit }, text) {
      // search
      const orgsPromise = orgIndex.search(text, {
        filters: 'isActive:true'
      }).then(results => {
        commit('updateSearchResultsOrgs', results)
      })

      // events, only future/current ones
      const timestamp = Date.now() / 1000
      const eventsPromise = eventIndex.search(text, {
        filters: `isPublished:true AND isActive:true AND organization.isActive:true AND (date._seconds >= ${timestamp} OR endDate._seconds >= ${timestamp})`
      }).then(results => {
        commit('updateSearchResultsEvents', results)
      })

      return Promise.all([orgsPromise, eventsPromise])
    }
  },

  mutations: {
    ...vuexfireMutations,

    updateDiscoverEvents (_, arr) {
      state.discoverEvents = arr
    },

    updateMapEvents (_, arr) {
      state.mapEvents = arr
    },

    updateMapOrgs (_, arr) {
      state.mapOrgs = arr
    },

    setBrowseOrgsSort (_, key) {
      // Update key
      state.browseOrgsSort = key
      state.browseOrgs = []
    },

    setDiscoverSort (_, key) {
      // Update key
      state.discoverSort = key
      state.discoverEvents = []
    },

    setDiscoverFeedTab (_, key) {
      state.discoverEvents = key
    },

    setMyFeedSort (_, key) {
      // Update key
      state.myFeedSort = key
      state.myFeedEvents = []
    },

    setOrgEventsSort (_, key) {
      // Update key
      state.orgEventsSort = key
      state.orgEvents = []
    },

    resetOrgEvents () {
      state.orgEvents = []
    },

    setFollowedOrgs (_, orgs) {
      state.followedOrgs = orgs
    },

    setEventReactions (_, reactions) {
      state.eventReactions = reactions
    },

    setEnteredGiveaways (_, giveaways) {
      state.enteredGiveaways = giveaways
    },

    enterGiveaway (_, promoId) {
      state.enteredGiveaways.push(promoId)
      persistEnteredGiveaways()

      analytics.logEvent(AppEvents.ENTER_GIVEAWAY, {
        promo_id: promoId
      })
    },

    followOrg (_, orgId) {
      if (!state.followedOrgs.some(org => org.id === orgId)) {
        state.followedOrgs.push({
          id: orgId,
          subscribed: false
        })

        analytics.logEvent(AppEvents.FOLLOW_ORG, {
          org_id: orgId
        })

        state.myFeedEvents = []
        persistFollowedOrgs()
      }
    },

    unfollowOrg (_, orgId) {
      const index = state.followedOrgs.findIndex(org => org.id === orgId)
      if (index >= 0) {
        state.followedOrgs.splice(index, 1)

        analytics.logEvent(AppEvents.UNFOLLOW_ORG, {
          org_id: orgId
        })

        state.myFeedEvents = []
        persistFollowedOrgs()
      }
    },

    eventRemoveReaction(_, fullReactionId) {
      const splitArr = fullReactionId.split("-")
      const eventId = splitArr[0]
      const groupId = splitArr[1]
      const reactionId = splitArr[2]
      
      const eventGroupId = eventId + "-" + groupId
      const existingReactionsArr = state.eventReactions[eventGroupId]

      if (existingReactionsArr != null) {
        const index = existingReactionsArr.indexOf(reactionId);
        if (index > -1) {
          existingReactionsArr.splice(index, 1)
        }   
      }

      state.eventReactions[eventGroupId] = existingReactionsArr

      analytics.logEvent(AppEvents.REACTION_SELECTED, {
        reaction_id: reactionId,
        event_id: eventId,
        reactionGroup_id: groupId,
        didLike: false
      })
      
      persistEventReactions()
    },

    eventReaction(_, fullReactionId) {
      const splitArr = fullReactionId.split("-")
      const eventId = splitArr[0]
      const groupId = splitArr[1]
      const reactionId = splitArr[2]

      const eventGroupId = eventId + "-" + groupId
      let existingReactionsArr = state.eventReactions[eventGroupId]

      if (existingReactionsArr == null) {
        existingReactionsArr = Array<string>()
      }

      existingReactionsArr.push(reactionId)

      state.eventReactions[eventGroupId] = existingReactionsArr

      // Reset reactions
      // state.eventReactions = new Map<string, Array<string>>()

      analytics.logEvent(AppEvents.REACTION_SELECTED, {
        reaction_id: reactionId,
        event_id: eventId,
        reactionGroup_id: groupId,
        didLike: true
      })

      persistEventReactions()
    },

    updateSearchResultsOrgs (_, results) {
      state.searchResultsOrgs = results.hits
    },

    updateSearchResultsEvents (_, results) {
      state.searchResultsEvents = results.hits
    },

    async updateMenuCount(_, menuId: string) {
      try {
        const linkRef = collections.menus.doc(menuId)
        const increment = firebase.firestore.FieldValue.increment(1);
        linkRef.update({"webClicks": increment})
  
        const linkDoc = await linkRef.get()
        const linkData = linkDoc.data()

        if (linkData != null) {
          analytics.logEvent(AppEvents.MENU_SELECTED, {
            menu_id: menuId,
            org_id: linkData.orgId
          })
        }
      } catch (e) {
        console.error('Failed to updateMenuCount', menuId, e)
      }
    },

    async updateLinkCount(_, linkId: string) {
      try {
        const linkRef = collections.links.doc(linkId)
        const increment = firebase.firestore.FieldValue.increment(1);
        linkRef.update({"webClicks": increment})
  
        const linkDoc = await linkRef.get()
        const linkData = linkDoc.data()

        if (linkData != null) {
          analytics.logEvent(AppEvents.LINK_FEED_SELECTED, {
            link_id: linkId,
            org_id: linkData.orgId
          })
        }
      } catch (e) {
        console.error('Failed to updateLinkCount', linkId, e)
      }
    },

    // Update the "Beerscovery Feed" link count on the org level 
    async updateFeedLinkCount(_, orgId: string) {
      try {
        const orgRef = collections.organizations.doc(orgId)
        const increment = firebase.firestore.FieldValue.increment(1);
        orgRef.update({"feedLinkClicks": increment})
  
        const orgDoc = await orgRef.get()
        const orgData = orgDoc.data()

        analytics.logEvent(AppEvents.BEERSCOVERY_FEED_LINK_SELECTED, {
          org_id: orgId
        })
      } catch (e) {
        console.error('Failed to updateFeedLinkCount', orgId, e)
      }
    }

  }
})
