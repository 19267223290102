

























































































import Vue from "vue";
import { Component } from 'vue-property-decorator'
import { mapState } from 'vuex'
import * as AppEvents from './model/AppEvents'
import { analytics } from './firebase'
import { canUseLocalStorage } from './util/Storage'

const INSTAGRAM_HREF_BASE = 'https://www.instagram.com'

@Component({
  computed: {
    ...mapState([
      'activeTab',
      'activeEvent',
      'activeOrg'
    ])
  }
})
export default class App extends Vue {

  private activeEvent

  private activePromotion

  private activeOrg

  private showSearch = false

  private searchText = ''
  
  private doSearch() {
    this.$router.push(`/search?s=${this.searchText}`)
  }

  private get showMyFeed () {
    return canUseLocalStorage()
  }

  private get tabclass () {
    if (this.$vuetify.breakpoint.mobile) {
      return 'px-1'
    }
    return ''
  }

  private created () {
    // check for outbound links on left and middle clicks
    document.addEventListener('click', this.preLinkNav, false)
    document.addEventListener('auxclick', this.preLinkNav, false)
  }

  private preLinkNav (e) {
    // ignore anything that's not an anchor
    if (e.target.tagName !== 'A') {
      return
    }

    const href = e.target?.href
    if (href == null) {
      return
    }

    // TODO: Phone #s
    if (href.startsWith(INSTAGRAM_HREF_BASE)) {
      if (href.includes('/explore/tags/')) {
        // hashtag
        const hashtag = e.target.pathname.substring(14)
        analytics.logEvent(AppEvents.INSTA_HASHTAG_TOUCHED, {
          orgId: this.activeOrg.id,
          eventId: this.activeEvent.id,
          hashtag
        })
      } else {
        // mention
        const mention = e.target.pathname.substring(1)
        analytics.logEvent(AppEvents.INSTA_MENTION_TOUCHED, {
          orgId: this.activeOrg.id,
          eventId: this.activeEvent.id,
          mention
        })
      }
    } else if (e.target.host !== window.location.host){
      // regular link that's navigating away from our app
      analytics.logEvent(AppEvents.URL_TOUCHED, {
        orgId: this.activeOrg.id,
        eventId: this.activeEvent.id,
        url: href
      })
    }
  }

}
