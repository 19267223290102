import * as firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/messaging'

// firebase init
const config = {
  apiKey: "AIzaSyBlmJyazzgH4T_3lgPDHPE9bhQAu0_0otM",
  authDomain: "brewscovery-28199.firebaseapp.com",
  databaseURL: "https://brewscovery-28199.firebaseio.com",
  projectId: "brewscovery-28199",
  storageBucket: "brewscovery-28199.appspot.com",
  messagingSenderId: "931752505395",
  appId: "1:931752505395:web:a5ee0c46505c562023f346",
  measurementId: "G-WKFYKMY137"
}
firebase.initializeApp(config)
export const analytics = firebase.analytics()

// firebase utils
export const db = firebase.firestore()

// firebase root collection refs
export const collections = {
  promotions: db.collection('promotions'),
  links: db.collection('links'),
  merch: db.collection('merch'),
  beers: db.collection('beers'),
  events: db.collection('events'),
  organizations: db.collection('organizations'),
  iwantin: db.collection('iwantin'),
  slugs: db.collection('urlMaps'),
  giveawayEntries: db.collection('giveaway-entries'),
  menus: db.collection('menus')
}

// functions
const funcs = firebase.functions()
export const functions = {
  subscribeWebNotification: funcs.httpsCallable('subscribeWebNotification'),
  unsubscribeWebNotification: funcs.httpsCallable('unsubscribeWebNotification'),
  updateEventReaction: funcs.httpsCallable('updateEventReaction')
}
