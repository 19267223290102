export const SCREEN_DISCOVER = 'Discover'
export const SCREEN_UPCOMING = 'Upcoming'
export const SCREEN_BROWSE = 'BrowseVC'
export const SCREEN_EVENT = 'EventDetailsVC'
export const SCREEN_ABOUT = 'About'
export const SCREEN_ORG = 'OrganizationFeedVC'
export const SCREEN_ORG_LINKS = 'OrgLinksVC'
export const SCREEN_ORG_MENUS = 'OrgMenusVC'
export const SCREEN_ORG_MENU = 'MenuVC'
export const SCREEN_PROMOTIONS = 'Promotions'
export const SCREEN_PROMOTION = 'Promotion'
export const SCREEN_MAP = 'MapVC'
export const SCREEN_SEARCH = 'SearchVC'
export const SCREEN_IWANTIN = 'IWantInVC'

export const ERROR_DISCOVER = 'error_discover'
export const ERROR_ORGANIZATIONS = 'error_organizations'

export const EVENT_SELECTED = 'event_selected'
export const FOLLOW_ORG ='follow_org'
export const INSTA_HASHTAG_TOUCHED = 'insta_hashtag_touched'
export const INSTA_MENTION_TOUCHED = 'insta_mention_touched'
export const ENTER_GIVEAWAY = 'enter_giveaway'
export const PROMOS_TAB_SELECTED = 'promos_tab_selected'
export const ORG_SELECTED = 'org_selected'
export const PHONE_TOUCHED = 'phone_touched'
export const SUBSCRIBE_ORG = 'subscribe_org'
export const UNFOLLOW_ORG ='unfollow_org'
export const UNSUBSCRIBE_ORG = 'unsubscribe_org'
export const URL_TOUCHED = 'url_touched'
export const REACTION_SELECTED = "reaction_selected"
export const LINK_FEED_SELECTED = "link_feed_selected"
export const PROMOTIONS_SELECTED = "promotions_selected"
export const BEERSCOVERY_FEED_LINK_SELECTED = "beerscovery_feed_link_selected"
export const MENU_SELECTED = "menu_selected"
