import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#14214A',
        secondary: '#1D3B6B',
        accent: '#6497B0'   // color 3 yet unussed, #005B97
      }
    }
  }
});
