let position: GeolocationPosition | null = null

const POSITION_CACHE_EXPIRY_TIME = 30000 // 30 seconds

function resetPosition () {
  position = null
}

function getPosition (options?): Promise<GeolocationPosition> {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  })
}

export async function getCurrentPosition (): Promise<GeolocationPosition> {
  if (position == null) {
    position = await getPosition()
    setTimeout(resetPosition, POSITION_CACHE_EXPIRY_TIME)
  }
  return position
}

