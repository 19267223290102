import Vue from 'vue'
import { firestore } from 'firebase/app'

import { format } from 'date-fns'
import isSameDay from 'date-fns/isSameDay'

const DATETIME_DISPLAY_FORMAT = 'iiii, MMM d @ h:mm aa'
const TIME_ONLY_DISPLAY_FORMAT = 'h:mm aa'

type AlgoliaTimestamp = {
  _nanoseconds: number;
  _seconds: number;
}

function asDate (input: firestore.Timestamp | AlgoliaTimestamp): Date {
  if (input instanceof firestore.Timestamp) {
    return input.toDate()
  } else {
    // assume its from algolia
    const ts = input as AlgoliaTimestamp
    return new Date(ts._seconds * 1000)
  }

}

function dateSpan (s: string): string {
  return `<span class="date">${s}</span>`
}

Vue.directive('date-time-display', function (el, binding) {
  const date = asDate(binding.value)
  el.innerHTML = format(date, DATETIME_DISPLAY_FORMAT)
})

Vue.directive('date-range-display', function (el, binding) {
  const {start, end} = binding.value
  const startDate = asDate(start)
  const endDate = end != null ? asDate(end) : null
  let txt = dateSpan(format(startDate, DATETIME_DISPLAY_FORMAT))
  if (endDate != null) {
    txt += ' to '
    // If days are the same, just render date once
    if (isSameDay(startDate, endDate)) {
      txt += format(endDate, TIME_ONLY_DISPLAY_FORMAT)
    } else {
      txt += format(endDate, DATETIME_DISPLAY_FORMAT)
    }
  }
  el.innerHTML = txt
})

export function formatDateRangeDisplay (start: firestore.Timestamp | AlgoliaTimestamp, end?: firestore.Timestamp | AlgoliaTimestamp): string {
  const startDate = asDate(start)
  const endDate = end != null ? asDate(end) : null
  let txt = format(startDate, DATETIME_DISPLAY_FORMAT)
  if (endDate != null) {
    txt += ' to '
    // If days are the same, just render date once
    if (isSameDay(startDate, endDate)) {
      txt += format(endDate, TIME_ONLY_DISPLAY_FORMAT)
    } else {
      txt += format(endDate, DATETIME_DISPLAY_FORMAT)
    }
  }
  return txt
}

