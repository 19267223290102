import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { collections, analytics } from '../firebase'
import * as AppEvents from '../model/AppEvents'
import { canUseLocalStorage } from '../util/Storage'

Vue.use(VueRouter);

// let currentSlug: string | null = null

const appleAppMeta = {
  name: 'apple-itunes-app',
  content: 'app-id=1522808778, app-argument=https://apps.apple.com/us/app/beerscovery/id1522808778'
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/discover"
  },
  {
    path: "/links",
    redirect: "/beerscoveryapp/links"
  },
  {
    path: "/coaster",
    redirect: "/about"
  },
  {
    path: "/discover",
    name: AppEvents.SCREEN_DISCOVER,
    component: () => import(/* webpackChunkName: "discover" */ "../views/Discover.vue"),
    meta: {
      selectedTabIndex: 0,
      title: 'Discover Beer Events - Beerscovery',
      metaTags: [
        {
          name: 'description',
          content: 'Discover beer events and releases!'
        },
        appleAppMeta
      ]
    }
  },
  {
    path: "/promotions",
    name: AppEvents.SCREEN_PROMOTIONS,
    component: () => import(/* webpackChunkName: "discover" */ "../views/Discover.vue"),
    meta: {
      selectedTabIndex: 1,
      title: 'Discover Beer Promotions - Beerscovery',
      metaTags: [
        {
          name: 'description',
          content: 'Discover beer promotions!'
        },
        appleAppMeta
      ]
    }
  },
  {
    path: "/upcoming",
    name: AppEvents.SCREEN_UPCOMING,
    component: () => import(/* webpackChunkName: "upcoming" */ "../views/Upcoming.vue"),
    meta: {
      title: 'Upcoming Beer Events - Beerscovery',
      metaTags: [
        {
          name: 'description',
          content: 'Discover upcoming beer events and releases!'
        },
        appleAppMeta
      ]
    }
  },
  {
    path: "/browse",
    name: AppEvents.SCREEN_BROWSE,
    component: () => import(/* webpackChunkName: "browse" */ "../views/Browse.vue"),
    meta: {
      title: 'Browse Beer Organizations - Beerscovery',
      metaTags: [
        {
          name: 'description',
          content: 'Browse breweries, bottle shops, bars, restaraunts, and more!'
        },
        appleAppMeta
      ]
    }
  },
  {
    path: "/org/:id",
    name: AppEvents.SCREEN_ORG,
    component: () => import(/* webpackChunkName: "org" */ "../views/OrgEvents.vue"),
    meta: {
      title: 'Organization Feed',
      metaTags: [
        {
          name: 'description',
          content: 'View events, releases and general info for this organization.'
        },
        appleAppMeta
      ]
    }
  },
  {
    path: "/org/:id/links",
    name: AppEvents.SCREEN_ORG_LINKS,
    component: () => import(/* webpackChunkName: "org" */ "../views/OrgLinks.vue"),
    meta: {
      title: 'Link Feed',
      metaTags: [
        {
          name: 'description',
          content: 'view links and urls from the oranization'
        },
        appleAppMeta
      ]
    }
  },
  {
    path: "/org/:id/menus",
    name: AppEvents.SCREEN_ORG_MENUS,
    component: () => import(/* webpackChunkName: "org" */ "../views/OrgMenus.vue"),
    meta: {
      title: 'Menus',
      metaTags: [
        {
          name: 'description',
          content: 'view menus for the oranization'
        },
        appleAppMeta
      ]
    }
  },
  {
    path: "/menu/:id",
    name: AppEvents.SCREEN_ORG_MENU,
    component: () => import(/* webpackChunkName: "org" */ "../views/MenuView.vue"),
    meta: {
      title: 'Menu',
      metaTags: [
        {
          name: 'description',
          content: 'view view'
        },
        appleAppMeta
      ]
    }
  },
  {
    path: "/promo/:id",
    name: AppEvents.SCREEN_PROMOTION,
    component: () => import(/* webpackChunkName: "org" */ "../views/PromotionView.vue"),
    meta: {
      title: 'Promotion',
      metaTags: [
        {
          name: 'description',
          content: 'view promo'
        },
        appleAppMeta
      ]
    }
  },
  {
    path: "/event/:id",
    name: AppEvents.SCREEN_EVENT,
    component: () => import(/* webpackChunkName: "event" */ "../views/Event.vue"),
    meta: {
      title: 'Event Detail',
      metaTags: [
        {
          name: 'description',
          content: 'View events, releases and general info for this organization.'
        },
        appleAppMeta
      ]
    }
  },
  {
    path: "/map",
    name: AppEvents.SCREEN_MAP,
    component: () => import(/* webpackChunkName: "map" */ "../views/Map.vue"),
    meta: {
      title: 'Organizations and Event Venues - Beerscovery',
      metaTags: [
        {
          name: 'description',
          content: 'Find beer release events, breweries, bottle shops, bars, restaraunts, and more!'
        },
        appleAppMeta
      ]
    }
  },
  {
    path: "/about",
    name: AppEvents.SCREEN_ABOUT,
    component: () => import(/* webpackChunkName: "event" */ "../views/IWantIn.vue"),
    meta: {
      title: 'About Beerscovery',
      metaTags: [
        {
          name: 'description',
          content: 'Download our apps or enter your info to get more info about getting your events and release into Beerscovery!'
        },
        appleAppMeta
      ]
    }
  },
  {
    path: "/search",
    name: AppEvents.SCREEN_SEARCH,
    component: () => import(/* webpackChunkName: "search" */ "../views/Search.vue"),
    meta: {
      title: 'Search Results - Beerscovery',
      metaTags: [
        {
          name: 'description',
          content: 'Find beer release events, breweries, bottle shops, bars, restaraunts, and more!'
        },
        appleAppMeta
      ]
    }
  },
  {
    path: "/iwantin",
    name: AppEvents.SCREEN_IWANTIN,
    component: () => import(/* webpackChunkName: "iwantin" */ "../views/IWantIn.vue"),
    meta: {
      title: 'Account For My Business - Beerscovery',
      metaTags: [
        {
          name: 'description',
          content: 'Want in? Submit your contact information for a Beerscovery account and more information.'
        },
        appleAppMeta
      ]
    }
  },
  {
    path: "/:slug",
    async beforeEnter (to, from, next) {
      const id = to.params.slug
      const slugRef = await collections.slugs.doc(id).get()

      if (slugRef.exists) {
        const slug = slugRef.data()
        if (slug != null) {
          // currentSlug = id
          next(slug.path)

          return
        }
      }
      next('/')
    }
  },
  {
    path: "/:slug/links",
    async beforeEnter (to, from, next) {
      const id = to.params.slug
      const slugRef = await collections.slugs.doc(id).get()

      if (slugRef.exists) {
        const slug = slugRef.data()
        if (slug != null) {
          // currentSlug = id
          next(slug.path + "/links")

          return
        }
      }
      next('/')
    }
  },
  {
    path: "/:slug/menus",
    async beforeEnter (to, from, next) {
      const id = to.params.slug
      const slugRef = await collections.slugs.doc(id).get()

      if (slugRef.exists) {
        const slug = slugRef.data()
        if (slug != null) {
          // currentSlug = id
          next(slug.path + "/menus")

          return
        }
      }
      next('/')
    }
  },
];

// detect storage for My Feed
if (canUseLocalStorage()) {
  routes.splice(0, 0, {
    path: "/feed",
    name: "Feed",
    component: () => import(/* webpackChunkName: "feed" */ "../views/Feed.vue")
  })
}

const router = new VueRouter({
  mode: 'history',
  routes
});

router.afterEach(async (to, from) => {
  // trigger analytics events
  const source = from.name
  if (to.name === AppEvents.SCREEN_ORG) {
    analytics.logEvent(AppEvents.ORG_SELECTED, {
      org_id: to.params.id,
      source
    })
  } else if (to.name === AppEvents.SCREEN_EVENT) {
    analytics.logEvent(AppEvents.EVENT_SELECTED, {
      event_id: to.params.id,
      source
    })
  }

  /* // This rewrites the URL back to the slug */
  /* if (currentSlug != null) { */
  /*   if (history) { */
  /*     history.replaceState({}, '', location.origin + '/' + currentSlug) */
  /*   } */
  /* } */

  // set screen
  analytics.setCurrentScreen('' + to.name)
})

// This callback runs before every route change, including on page load.
// https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode?.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;
