import Vue from 'vue'

import * as linkify from 'linkifyjs'
import hashtag from 'linkifyjs/plugins/hashtag'
import mention from 'linkifyjs/plugins/mention'

// Install linkify plugins for #hashtags and @mentions
hashtag(linkify)
mention(linkify)

import linkifyHtml from 'linkifyjs/html'

// Redirect hashtags and mentions to instagram
function formatHref (href: string, type: string) {
  const val = href.substring(1)
  if (type === 'hashtag') {
    href = `https://www.instagram.com/explore/tags/${val}`
  } else if (type === 'mention') {
    href = `https://www.instagram.com/${val}`
  }
  return href
}


Vue.directive('linkify', function (el) {
  el.innerHTML = linkifyHtml(el.innerHTML, { formatHref } )
})
